import Vue from 'vue'
// 引入vant框架 在app之前引入
import vant from "vant"
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

//npm i vant -S
import "vant/lib/index.css"
Vue.use(vant)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
