<template>
  <div class="home" ref="home">
    <div class="big-html" ref="win">
      <header>
        <img src="./assets/logo.png" alt="" />
        <div class="header-list">
          <ul>
            <li>首页</li>
            <li>介绍</li>
            <li>案例</li>
            <li class="active">下载app</li>
            <li>关于我们</li>
          </ul>
        </div>
        <img class="more" @click="clickMore()" src="./assets/列表.png" alt="" />
      </header>
      <div class="more-list" v-show="moreShow">
        <ul>
          <li @click="clickMore('li')">首页</li>
          <li @click="clickMore('li')">介绍</li>
          <li @click="clickMore('li')">案例</li>
          <li @click="clickMore('li')" class="active">下载app</li>
          <li @click="clickMore('li')">关于我们</li>
        </ul>
      </div>
      <section>
        <div class="content">
          <div class="left">
            <img src="./assets/手机.png" alt="" />
          </div>
          <div class="right">
            <div class="title">
              <img src="./assets/文案.png" alt="" />
            </div>
            <div class="downLoad">
              <p>扫码下载企盟APP</p>
              <div class="btn">
                <div class="btn-left">
                  <img
                    src="./assets/Appstore下载.png"
                    @click="goPhone()"
                    alt=""
                  />
                  <img src="./assets/Android下载.png" alt="" />
                </div>
                <div class="btn-right">
                  <img src="./assets/二维码.png" alt="" />
                </div>
              </div>
              <p>建议使用浏览器扫码访问，马上体验企盟IM APP</p>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div class="content">
          <div class="top">
            <img src="./assets/footerLogo.png" alt="" />
            <ul>
              <li>首页</li>
              <li>介绍</li>
              <li>案例</li>
              <li>下载app</li>
              <li>关于我们</li>
            </ul>
          </div>
          <div class="bottom">
            <p>
              <span
                >Copyright©2021-2021 中山市高竹网络科技有限公司 版权所有</span
              >
              <a href="https://beian.miit.gov.cn/#/Integrated/index"
                >备案号：粤ICP备20045401号</a
              >
              <div style="width:300px;margin:0 auto; padding:20px 0;">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44200002444853" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="./assets/gongan.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备 44200002444853号</p></a>
              </div>
            </p>
            <span>技术支持: 中山市高竹网络科技有限公司</span>
          </div>
        </div>
      </footer>
    </div>

    <div class="phone-html" ref="phone">
      <section class="phone-section">
        <div class="header">
          <img src="./assets/phoneLogo.png" class="logo" alt="" />
          <img src="./assets/text.png" class="text" alt="" />
        </div>
        <div class="phone">
          <img src="./assets/phone.png" alt="" />
        </div>

        <div class="btn">
          <img src="./assets/loading.png" alt="" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moreShow: false,
      width: "",
    };
  },
  methods: {
    clickMore(dom) {
      if (dom == "li") {
        this.moreShow = false;
        return;
      }
      this.moreShow = !this.moreShow;
    },
    goPhone() {
      this.$router.push({ name: "phone" });
    },
    getWidth() {
      var ua = navigator.userAgent;
      if (ua.indexOf("Windows") > -1) {
        console.log(111);
        this.$refs.phone.style.display = "none";
        this.$refs.win.style.display = "block";
        return;
      }
      if (
        ua.indexOf("Android") > -1 ||
        ua.indexOf("iPhone") > -1 ||
        ua.indexOf("iPad") > -1 ||
        ua.indexOf("iPod") > -1
      ) {
        console.log(111);
        this.$refs.phone.style.display = "block";
        this.$refs.win.style.display = "none";
        return;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
};
</script>

<style lang="less">
html,
body,
#__nuxt,
#__layout,
#__layout > div {
  margin: 0; //消除浏览器默认的margin，可以不加
  padding: 0; //消除浏览器默认的padding，可以不加
  user-select: none;
  font-family: PF;
  height: 100%;
  width: 100%;
  background-color: #f5f5f6;
  border: none;
}
ul,
ol {
  list-style: none;
}
.home {
  width: 100%;
  height: 100%;
  .big-html {
    width: 100%;
    header {
      display: flex;
      position: relative;
      width: 100%;
      height: 72px;
      padding: 0 358px;
      box-sizing: border-box;
      justify-content: space-between;
      background-color: #fdfdfd;
      align-items: center;
      ul {
        height: 100%;
        display: flex;
        align-items: center;
        li {
          font-family: PingFang-SC-Medium;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #2b2e2e;
          margin-left: 76px;
          position: relative;
          &.active {
            &::after {
              position: absolute;
              bottom: -8px;
              left: 50%;
              transform: translateX(-50%);
              content: "";
              display: block;
              width: 32px;
              height: 4px;
              background-color: #1e78eb;
            }
          }
        }
      }
      .more {
        display: none;
        height: 35px;
      }
    }
    .more-list {
      position: fixed;
      left: 0;
      right: 0;
      top: 72px;
      ul {
        width: 100%;
        padding: 0;
        margin: 0;
        font-size: 0;
        li {
          background-color: #fff;
          border-bottom: 1px solid #dddd;
          padding: 20px;
          font-size: 20px;
          width: 100%;
          text-align: center;
          &:hover {
            background-color: rgb(170, 170, 170);
          }
          &.active {
            background-color: rgb(170, 170, 170);
          }
        }
      }
    }
    section {
      width: 100% !important;
      height: 900px;
      background-color: #f2f2f6;
      background: url("./assets/背景.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 57px 0 68px 0;
      box-sizing: border-box;
      .content {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        display: flex;
        overflow: hidden;
      }
      .left {
        flex: 0 0 50%;
      }
      .right {
        margin-top: 87px;
        flex: 0 0 50%;
        padding-left: 50px;
        box-sizing: border-box;
        .title {
          margin-bottom: 64px;
        }
        .downLoad {
          p {
            &:nth-of-type(1) {
              margin-bottom: 19px;
              font-family: PingFang-SC-Medium;
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 28px;
              letter-spacing: 0px;
              color: #595959;
            }
            &:nth-of-type(2) {
              margin-top: 19px;
              font-family: PingFang-SC-Regular;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 21px;
              letter-spacing: 0px;
              color: #595959;
              opacity: 0.8;
            }
          }
          .btn {
            img {
              margin-right: 20px;
              display: block;
              margin-bottom: 8px;
            }
            display: flex;
          }
        }
      }
    }
    footer {
      background-color: #0a1633;
      width: 100%;
      padding: 69px 360px 36px 360px;
      box-sizing: border-box;
      .content {
        max-width: 1920px;
        min-width: 1010px;
        margin: 0 auto;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 41px;
          border-bottom: 1px solid #3b455c;
          ul {
            display: flex;
            li {
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #ffffff;
              margin-left: 64px;
            }
          }
        }
        .bottom {
          margin-top: 37px;
          display: flex;
          justify-content: space-between;
          span {
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #6a728a;
            margin-right: 10px;
          }
          a {
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #6a728a;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .phone-html {
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    height: 100% !important;
    position: relative;
    z-index: 1000;
    background: url(./assets/bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    .header {
      position: absolute;
      top: 6%;
      z-index: 2000;
      width: 100%;
      img {
        display: block;
        margin: 0 auto;
      }
      .logo {
        height: auto;
        width: 19%;
      }

      .text {
        height: auto;
        width: 66%;
      }
    }

    .phone {
      width: 100%;
      position: absolute;
      bottom: 0;
      font-size: 0;
      display: flex;
      align-items: flex-end;
      img {
        width: 100%;
        height: auto;
      }
    }

    .btn {
      position: absolute;
      width: 65%;
      height: auto;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and(max-width:1522px) {
  .header-list {
    display: none;
  }
  header {
    padding: 30px !important;
  }

  .more {
    display: block !important;
  }
  section {
    height: auto !important;
    .content {
      display: block !important;
    }

    .right {
      .downLoad {
        text-align: center;
      }
      .title {
        text-align: center;
      }
      .btn {
        justify-content: center;
      }
    }
  }

  footer {
    padding: 20px !important;
    .content {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }
}

@media screen and(min-width:750px) {
  .phone-html {
    display: none;
  }
}
</style>
